import { render, staticRenderFns } from "./modifyPriceModal.vue?vue&type=template&id=95e09d54&scoped=true&"
import script from "./modifyPriceModal.vue?vue&type=script&lang=js&"
export * from "./modifyPriceModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e09d54",
  null
  
)

export default component.exports