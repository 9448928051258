<template>
  <div class="container_box">
    <Tabs v-model="pageParams.shelveStatus" :animated="false" class="tabs_styles" @on-click="search()">
      <TabPane v-for="item in salesStatusList" :label="item.title" :name="item.value.toString()"></TabPane>
    </Tabs>
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              inputType="textarea"
              :selectStyle="{width: '110px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000396')">
              <Input :placeholder="$t('key1005502')" v-model.trim="pageParams.cnName" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="configCharacters + $t('key1000394')" prop="ymsProductCategoryId">
              <classificationSelect ref="classificationDom" @selectValue="changeValue"></classificationSelect>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1005503')">
              <Select v-model="pageParams.auditStatus" transfer filterable>
                <Option v-for="item in auditStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002500')" prop="productTypes">
              <Select v-model="pageParams.productTypes" transfer multiple :placeholder="$t('key1000086')">
                <Option v-for="item in productTypeList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <!--添加商品-->
      <Button class="mr15" type="primary" @click="addProduct" v-if="getPermission('productInfo_insert')">{{ $t('key1000776') }}</Button>
      <!--获取1688商品-->
      <Button class="mr15" @click="addAlibabaProduct">{{ $t('key1005507') }}</Button>
      <!--采集1688商品 userInfo.merchantId === '088025' -->
      <Button v-if="collectProductsTalg" class="mr15" @click="collectProductsBtn">{{ $t('key1005508') }}</Button>
      <!--导出商品-->
      <Dropdown transfer @on-click="exportProductBtn" class="mr15" v-if="getPermission('productGoods_excelExport')">
        <Button>
          <span>{{ $t('key1005506') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002222') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002223') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!--批量操作-->
      <Dropdown transfer class="mr15" @on-click="batchOperationBtn" v-if="batchButtonPermissions(batchOperationList)">
        <Button>
          <span>{{ $t('key1002438') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem v-for="item in batchOperationList" :name="item.name" v-if="getPermission(item.permissionKey)">{{ item.title }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!--打印条码、打印二维码-->
      <Dropdown v-if="getPermission('productInfo_appendProductInfoExpressBill')"
        transfer class="mr15" @on-click="printBtn">
        <Button>
          <span>{{ $t('key1003678') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="0">{{ $t('key1005509') }}</DropdownItem>
          <DropdownItem name="1">{{ $t('key1000408') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        :loading="tableLoading"
        border
        max-height="530"
        :columns="tableColumns"
        :data="tableData"
        @on-selection-change="tableChange">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!-- 操作日志 -->
    <operationLogModal ref="operationLogTalg" :productId="productId" operationType="spu"></operationLogModal>
    <!--批量改价弹窗-->
    <commonImportModal
      ref="batchPrice"
      :importTitle="$t('key1005504')"
      :uploadAction="batchPriceApi"
      :importOKText="$t('key1000253')"
      @updateData="updateData"
      downloadText="productGoodsPriceTemplate.xlsx">
      {{ $t('key1001789') }}<br/>
      {{ $t('key1005510') }}<br/>
      {{ $t('key1002971') }} .xls .xlsx .csv;<br/>
      {{ $t('key1002972') }}<br/>
      {{ $t('key1005511') }}<br/>
    </commonImportModal>
    <!--修改价格弹窗-->
    <modifyPriceModal ref="modifyPrice" @updateData="updateData"></modifyPriceModal>
    <!--导入商品弹窗-->
    <importCommodityInfoModal ref="commodityInfo" @updateData="updateData"></importCommodityInfoModal>
    <!--获取1688商品弹窗-->
    <alibabaProductModal ref="alibabaProduct" @updateData="updateData"></alibabaProductModal>
    <!--批量上架/停售弹窗-->
    <commonImportModal
      ref="batchListing"
      :importTitle="$t('key1005505')"
      :uploadAction="batchListingApi"
      :importOKText="$t('key1000253')"
      @updateData="updateData"
      downloadText="importUpdateProductGoodsStatus.xlsx">
      {{ $t('key1001789') }}<br/>
      {{ $t('key1005512') }}<br/>
      {{ $t('key1002971') }} .xls .xlsx .csv;<br/>
      {{ $t('key1002972') }}<br/>
      {{ $t('key1005511') }}<br/>
    </commonImportModal>
    <!--导入库存的弹窗-->
    <commonImportModal
      ref="importInventory"
      :importTitle="$t('key1000561')"
      importType="privateWarehouse"
      :uploadAction="importUrl"
      :importOKText="$t('key1000253')"
      fileName="files"
      @uploadSuccess="search()"
      downloadText="inventory.xlsx">
    </commonImportModal>
    <!--库存详情弹窗-->
    <inventoryDetailsModal ref="inventoryDetails" @updateData="updateData"></inventoryDetailsModal>
    <!--导出订单字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1005506')"
      paramKeyColums="supplierProductInfoColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import operationLogModal from "@/components/common/operationLogModal";
import dropDownFilter from "@/components/common/dropDownFilter";
import commonImportModal from "@/components/common/commonImportModal";
import {SALESSTATUS, AUDITSTATUS, PRODUCTTYPES} from "@/utils/enum";
import importCommodityInfoModal from "@/views/supplier/components/productCenter/importCommodityInfoModal";
import alibabaProductModal from "@/views/supplier/components/productCenter/alibabaProductModal";
import modifyPriceModal from "@/views/supplier/components/productCenter/modifyPriceModal";
import inventoryDetailsModal from "@/components/common/inventoryDetailsModal.vue";
import {pageJump, amountHandel, setConfigCharacters, commonSessionStorage, commonCopyContent, getBatchCategoryInfoData} from "@/utils/common";
import exportFieldsModal from "@/components/common/exportFieldsModal.vue";
import classificationSelect from "@/components/common/classificationSelect.vue";

export default {
  name: "productGoods",
  mixins: [Mixin],
  components: {
    classificationSelect,
    exportFieldsModal,
    operationLogModal,
    dropDownFilter,
    commonImportModal,
    importCommodityInfoModal,
    alibabaProductModal,
    modifyPriceModal,
    inventoryDetailsModal
  },
  data() {
    let v = this;
    return {
      defaultSelectValue: 1,
      dropDownInputList: [
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1000777'), value: 1, placeholder: alias2f085dd487c1465383484787b29fef2c.t('key1000778'), parameterKey: 'spuList'},
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1000402'), value: 2, placeholder: alias2f085dd487c1465383484787b29fef2c.t('key1005515'), parameterKey: 'skus'},
        {title: `${setConfigCharacters}SPU`, value: 3, placeholder: `${alias2f085dd487c1465383484787b29fef2c.t('key1000287')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1000773')}`, parameterKey: 'ymsSpuList'},
        {title: `${setConfigCharacters}SKU`, value: 4, placeholder: `${alias2f085dd487c1465383484787b29fef2c.t('key1000287')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1005513')}`, parameterKey: 'ymsSkus'}
      ],
      total: 0, // 总记录数
      pageParamsStatus: false, // 是否重新加载货品列表数据
      pageParamsPrototype: null, // 当前货品信息原型(重置用到)
      pageParams: { // 货品列表筛选参数
        cnName: null,
        orderBy: 'createdTime',
        upDown: 'down',
        pageSize: 50,
        pageNum: 1,
        ymsProductCategoryId: null,
        ymsSpuList: '',
        skus: '',
        ymsSkus: '',
        spuList: '',
        auditStatus: 'null',
        shelveStatus: '1',
        productTypes: []
      },
      tableData: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000784'),
          key: 'commodityInfo',
          align: 'left',
          minWidth: 350,
          render(h, params) {
            let supplierSpu = params.row.spu || ''; // 供应商SPU
            let ymsSpu = params.row.ymsSpu || ''; // 云卖SPU
            let name = params.row.name;
            let list = [
              v.tableImg(h, params, 'imagePath', '', null, null, true),
              h('div', {
                class: 'text-align-left pl10'
              }, [
                h('Alink', {
                  props: {
                    linkName: name,
                    textAlign: 'left',
                    className: 'mb5',
                    AlinkShow: v.getPermission('productInfo_detail')
                  },
                  on: {
                    AlinkBtn: () => {
                      const {href} = v.$router.resolve({
                        path: '/productDetails',
                        query: {
                          type: 'look',
                          productId: params.row.productId
                        }
                      });
                      window.open(href, '_blank');
                    }
                  }
                }),
                h('p', {class: 'flex align-items-center'}, [
                  h('span', {style: {color: '#000'}}, alias2f085dd487c1465383484787b29fef2c.t('key1000397') + supplierSpu),
                  supplierSpu ? h('Icon', {
                    props: {
                      type: 'ios-copy-outline',
                      size: 18,
                      color: '#01a6ee'
                    },
                    style: {
                      marginLeft: '10px',
                      cursor: 'pointer'
                    },
                    attrs: {
                      title: alias2f085dd487c1465383484787b29fef2c.t('key1000000')
                    },
                    on: {
                      click: () => {
                        commonCopyContent(supplierSpu, alias2f085dd487c1465383484787b29fef2c.t('key1000004'));
                      }
                    }
                  }) : ''
                ]),
                h('p', {class: 'flex align-items-center'}, [
                  h('span', {style: {color: '#000'}}, `${setConfigCharacters}SPU：` + ymsSpu),
                  ymsSpu ? h('Icon', {
                    props: {
                      type: 'ios-copy-outline',
                      size: 18,
                      color: '#01a6ee'
                    },
                    style: {
                      marginLeft: '10px',
                      cursor: 'pointer'
                    },
                    attrs: {
                      title: alias2f085dd487c1465383484787b29fef2c.t('key1000000')
                    },
                    on: {
                      click: () => {
                        commonCopyContent(ymsSpu, alias2f085dd487c1465383484787b29fef2c.t('key1000004'));
                      }
                    }
                  }) : ''
                ]),
              ])
            ];
            return h('div', {
              class: 'flex ptb10',
            }, list)
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1002505'),
          key: 'classificationName',
          minWidth: 260,
          align: 'left'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1002675'),
          key: 'auditStatus',
          minWidth: 120,
          align: 'left',
          render: (h, params) => {
            let status = params.row.auditStatus;
            let title = '';
            v.auditStatusList.map((item) => {
              if (item.value === status) {
                title = item.title;
              }
            });
            return h('div', [
              h('span', title),
              status === 2 ? h('Tooltip', {
                props: {
                  transfer: true,
                  maxWidth: 150,
                  content: params.row.shelvedRejectReason
                },
                style: {
                  marginLeft: '5px'
                }
              }, [
                h('Icon', {
                  props: {
                    type: 'md-information-circle',
                    size: 18
                  },
                  style: {
                    cursor: 'pointer',
                    color: 'red'
                  }
                })
              ]) : ''
            ]);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1002429'),
          key: 'skuNumber',
          minWidth: 120,
          align: 'left',
          sortable: true,
          render(h, params) {
            return h('Alink', {
              props: {
                linkName: params.row.skuNumber,
                textAlign: 'left',
                className: 'themeColor',
                AlinkShow: v.getPermission('productInfo_queryForSkuList')
              },
              on: {
                AlinkBtn: () => {
                  const {href} = v.$router.resolve({
                    path: '/viewSku',
                    query: {
                      productId: params.row.productId
                    }
                  });
                  window.open(href, '_blank');
                }
              }
            })
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000786'),
          key: 'wmsInventoryQuantity',
          minWidth: 140,
          align: 'left',
          sortable: true,
          render(h, params) {
            return h('Alink', {
              props: {
                linkName: params.row.wmsInventoryQuantity,
                textAlign: 'left',
                className: 'themeColor',
                AlinkShow: true
              },
              on: {
                AlinkBtn: () => {
                  v.$refs['inventoryDetails'].initInventoryDetails('supplier_supplierInventory', params.row);
                }
              }
            })
          }
        },
        {
          title: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1004684')}`,
          key: 'ymsInventoryQuantity',
          minWidth: 140,
          align: 'left',
          sortable: true,
          render(h, params) {
            return h('Alink', {
              props: {
                linkName: params.row.ymsInventoryQuantity,
                textAlign: 'left',
                className: 'themeColor',
                AlinkShow: true
              },
              on: {
                AlinkBtn: () => {
                  v.$refs['inventoryDetails'].initInventoryDetails('supplier_ymsInventory', params.row);
                }
              }
            })
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000405'),
          key: 'suggestPrices',
          minWidth: 130,
          align: 'left',
          render: (h, params) => {
            let list = params.row.suggestPrices;
            let max = '';
            let min = '';
            let num = 0;
            if (list && list.length > 0) {
              min = amountHandel(Math.min(...list));
              max = amountHandel(Math.max(...list));
              if (list.length <= 0) {
                if (max === 0) {
                  num = 0;
                } else {
                  num = max;
                }
              } else {
                if (min === max) {
                  num = max;
                } else {
                  num = min + ' ~ ' + max;
                }
              }
            }
            return h('div', [
              h('div', num),
              v.currencyState ? h('div', '(' + v.currencyState + ')') : ''
            ]);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001714'),
          key: 'time',
          align: 'left',
          width: 250,
          render: (h, params) => {
            let updatedTime = params.row.updatedTime ? v.$uDate.dealTime(params.row.updatedTime, 'fulltime') : '-';
            let createdTime = params.row.createdTime ? v.$uDate.dealTime(params.row.createdTime, 'fulltime') : '-';
            return h('div', {
              style: {
                textAlign: 'left'
              }
            }, [
              h('p', `${alias2f085dd487c1465383484787b29fef2c.t('key1000834')}${createdTime}`),
              h('p', `${alias2f085dd487c1465383484787b29fef2c.t('key1005514')}${updatedTime}`)
            ]);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000278'),
          width: 210,
          fixed: 'right',
          align: 'left',
          render(h, params) {
            let auditStatus = params.row.auditStatus; // 审核状态
            let shelveStatus = params.row.shelveStatus; // 销售状态
            let productId = params.row.productId;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000631'),
                    show: v.getPermission('productInfo_update'),
                    clickFn: () => {
                      const {href} = v.$router.resolve({
                        path: '/productDetails',
                        query: {
                          type: 'edit',
                          productId: productId
                        }
                      });
                      window.open(href, '_blank');
                    },
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000344'),
                    show: shelveStatus === 1 && v.getPermission('productInfo_undercarriage'),
                    clickFn: () => {
                      v.shelfBtn(productId);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000406'),
                    show: shelveStatus === 2 && v.getPermission('productInfo_dercarriage'),
                    clickFn: () => {
                      v.groundingBtn(productId);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1005516'),
                    show: v.getPermission('productGoods_batchUpdatePrice'),
                    clickFn: () => {
                      v.$refs['modifyPrice'].getModifyPriceList(productId);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1002509'),
                    show: v.getPermission('productInfo_queryForSkuList'),
                    clickFn: () => {
                      const {href} = v.$router.resolve({
                        path: '/viewSku',
                        query: {
                          productId: params.row.productId
                        }
                      });
                      window.open(href, '_blank');
                    }
                  },
                  {
                    text: `${alias2f085dd487c1465383484787b29fef2c.t('key1001508')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1004684')}`,
                    show: true,
                    clickFn: () => {
                      v.$refs['inventoryDetails'].initInventoryDetails('supplier_ymsInventory', params.row);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1002511'),
                    show: true,
                    clickFn: () => {
                      v.$refs['inventoryDetails'].initInventoryDetails('supplier_supplierInventory', params.row);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000215'),
                    show: v.getPermission('productGoodsOperationLog_query'),
                    clickFn: () => {
                      v.productId = productId;
                      v.$refs['operationLogTalg'].initializeData(productId);
                    }
                  },
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000091'),
                    show: v.getPermission('productInfo_delete') && (auditStatus === 0 || shelveStatus === 2
                      || (auditStatus === 2 && shelveStatus === 0)), // 只有草稿状态、停售的商品(待审核、停售), 暂存且审核驳回状态, 可以删除
                    clickFn: () => {
                      v.deleteSpu(productId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ], // 供应商商户的货品列表
      auditStatusList: AUDITSTATUS, // 审核状态
      salesStatusList: SALESSTATUS.filter((item) => item.value !== null), // 销售状态
      productId: null,
      selectTableData: [],
      batchPriceApi: api.post_productGoods_excelPricesImport,
      batchListingApi: api.post_productGoods_excelSkuStatusImport,
      importUrl: api.post_productInfo_inventoryExcelImport,
      batchOperationList: [
        {name: '1', permissionKey: 'productInfo_excelImport', title: alias2f085dd487c1465383484787b29fef2c.t('key1005517')},
        {name: '2', permissionKey: 'productInfo_inventoryExcelImport', title: alias2f085dd487c1465383484787b29fef2c.t('key1000561')},
        {name: '3', permissionKey: 'productGoods_excelPricesImport', title: alias2f085dd487c1465383484787b29fef2c.t('key1005504')},
        {name: '4', permissionKey: 'productGoods_excelSkuStatusImport', title: alias2f085dd487c1465383484787b29fef2c.t('key1005505')},
      ],
      exportType: null,
      customFieldExportList: [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000594'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'spu', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000777')},
            {paramKey: 'sku', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000402')},
            {paramKey: 'ymsSpu', paramValue: `${setConfigCharacters}SPU`},
            {paramKey: 'ymsSku', paramValue: `${setConfigCharacters}SKU`},
            {paramKey: 'cnName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1001241')},
            {paramKey: 'ymsProductCategoryName', paramValue: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1002505')}`},
            {paramKey: 'status', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000404')},
            {paramKey: 'productGoodsSpecification', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000403')},
            {paramKey: 'ymsWarehouseName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005518')},
            {paramKey: 'ymsInventoryAllottedQuantity', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005519')},
            {paramKey: 'ymsInventoryAvaiableQuantity', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005520')},
            {paramKey: 'wmsInventoryAllottedQuantity', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005521')},
            {paramKey: 'wmsInventoryAvaiableQuantity', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005522')},
            {paramKey: 'suggestPrice', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1005523')},
            {paramKey: 'productTypeStr', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1002519')}
          ]
        }
      ],
      paramKeys: 'collectGoodsConfig',
      collectProductsTalg: false,
      productTypeList: PRODUCTTYPES
    };
  },
  computed: {
    filterKeyList() {
      return this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    this.pageParamsPrototype = this.deepCopy(this.pageParams);
    if (this.$route.query) {
      if (this.$route.query.auditStatus) {
        this.pageParams.auditStatus = this.$route.query.auditStatus;
      }
      if (this.$route.query.shelveStatus) {
        this.pageParams.shelveStatus = this.$route.query.shelveStatus;
      }
    }
    // 判断是否是从获取、采集1688商品跳转过来,如果是默认切换到暂存状态查询
    let alibabaProductsTalg = localStorage.getItem('1688ProductTalg') ? JSON.parse(localStorage.getItem('1688ProductTalg')) : '';
    if (alibabaProductsTalg) {
      this.pageParams.shelveStatus = '0';
    }
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getCloudSalesClassification();
        await this.getProductCollection();
        await this.getUserName();
        await this.search();
      })();
    },
    // 获取商户是否配置采集商品功能
    getProductCollection() {
      let v = this;
      v.collectProductsTalg = false;
      let query = {
        businessId: v.userInfo.merchantId,
        paramKeys: [v.paramKeys]
      }
      v.axios.post(api.post_erpCommonSettingParam_queryByUK, query, {loading: true}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            data.map((item) => {
              if (item.paramKey === v.paramKeys) {
                let value = item.paramValue;
                if (value && value === 'Y') {
                  v.collectProductsTalg = true;
                }
              }
            })
          }
        }
      });
    },
    // 获取云卖分类的数据
    getCloudSalesClassification() {
      let obj = commonSessionStorage.getItem('erpConfig');
      let ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
      this.getCloudTreeData(api.post_ymsProductCategory_api_queryAll, 'tile', null, ymsSiteId).then((data) => {
        this.$nextTick(() => {
          this.$refs['classificationDom'].initClassificationData(data);
        });
      });
    },
    // 分类筛选
    changeValue(value) {
      this.pageParams.ymsProductCategoryId = value;
    },
    // 查询按钮
    search() {
      let v = this;
      v.pageParams.pageNum = 1;
      v.getList();
    },
    // 重置
    reset() {
      let v = this;
      v.pageParams = v.deepCopy(this.pageParamsPrototype);
      v.pageParams.ymsProductCategoryId = null;
      v.changeInput(1);
      v.$nextTick(() => {
        v.$refs['classificationDom'].reset();
      });
    },
    // 新增商品
    addProduct() {
      const {href} = this.$router.resolve({
        path: '/productDetails',
        query: {
          type: 'add'
        }
      });
      window.open(href, '_blank');
    },
    // 获取1688商品
    addAlibabaProduct() {
      this.$refs['alibabaProduct'].initFunAlibabaProduct();
    },
    // 删除spu
    deleteSpu(productId) {
      let v = this;
      v.$Modal.confirm({
        title: alias2f085dd487c1465383484787b29fef2c.t('key1000409'),
        onOk: () => {
          v.axios.delete(api.delete_productInfo + productId).then(response => {
            let code = response.data.code;
            let datas = response.data.datas || [];
            let unusualObj = {
              210018: alias2f085dd487c1465383484787b29fef2c.t('key1005524'),
              210015: alias2f085dd487c1465383484787b29fef2c.t('key1005525'),
              210058: alias2f085dd487c1465383484787b29fef2c.t('key1005526'),
              999999: alias2f085dd487c1465383484787b29fef2c.t('key1005527'),
            };
            if (datas.length > 0) {
              for (let i = 0; i < datas.length; i++) {
                let data = datas[i];
                let text = `SKU：${data.sku}${unusualObj[data.code]}`
                v.$Message.warning(text);
              }
            } else if (code === 0) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000581'));
              v.search();
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.shelveStatus = Number(v.pageParams.shelveStatus);
      let keyList = ['auditStatus'];
      for (let key of keyList) {
        params[key] = v.pageParams[key] === 'null' ? null : v.pageParams[key];
      }
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], ['\n']);
        }
      }
      return params;
    },

    // 获取商品列表
    getList() {
      let v = this;
      v.tableData = [];
      v.optionBtnWidth = [];
      v.total = 0;
      if (v.getPermission('productInfo_query')) {
        v.tableLoading = true;
        v.axios.post(api.post_productInfo_query, v.handleParameter()).then(response => {
          if (response.data.code === 0) {
            localStorage.removeItem('1688ProductTalg');
            let data = response.data.datas;
            let ymsProductCategoryIds = [];
            if (data) {
              let list = data.list || [];
              list.map((item) => {
                ymsProductCategoryIds.push(item.ymsProductCategoryId)
              });
              if (ymsProductCategoryIds.length > 0) {
                getBatchCategoryInfoData(api.post_ymsProductCategory_api_queryDetailByCategoryIds, list, ymsProductCategoryIds).then((newData) => {
                  v.tableData = newData;
                  v.updateTableDom('selection');
                })
              } else {
                v.tableData = list;
                v.updateTableDom('selection');
              }
              v.total = data.total;
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 停售SPU商品
    shelfBtn(productId) {
      let v = this;
      v.axios.put(api.put_productInfo_productId + `${productId}`).then(response => {
        if (response.data.code === 0) {
          this.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
          v.getList();
        }
      });
    },
    // 上架SPU商品
    groundingBtn(productId) {
      let v = this;
      v.axios.put(api.put_productInfo_dercarriage + `${productId}`).then(response => {
        if (response.data.code === 0) {
          this.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
          v.getList();
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },

    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    exportProductBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1002224'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1002225'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn(obj) {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.productIds = v.selectTableData.map((item) => {
          return item.productId
        });
      } else {
        query.productIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_productGoods_excelExport, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },

    // 操作之后，更新列表数据
    updateData(val) {
      if (val === '1688ProductTalg') {
        this.pageParams.shelveStatus = '0';
      }
      this.pageParamsStatus = true;
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      if (data) {
        this.selectTableData = data;
      }
    },
    // 打印条码、打印二维码
    printBtn(value) {
      let v = this;
      if (v.selectTableData.length > 0) {
        let list = [];
        v.selectTableData.map((item) => {
          list.push({
            productId: item.productId,
            quantity: 1,
            type: value // 0-条形码  1-二维码
          })
        });
        v.axios.post(api.post_productInfo_appendProductInfoExpressBill, list, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then((response) => {
          v.selectTableData = [];
          v.$refs['selection'].selectAll(false);
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.commonPrint(data, '1');
          } else if (response.data.code === 220026) {
            v.$Modal.warning({
              title: alias2f085dd487c1465383484787b29fef2c.t('key1000410'),
              content: alias2f085dd487c1465383484787b29fef2c.t('key1000411'),
              okText: alias2f085dd487c1465383484787b29fef2c.t('key1000138')
            });
          }
        });
      } else {
        v.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1003026'));
        return false;
      }
    },
    // 批量操作
    batchOperationBtn(value) {
      let v = this;
      let list = [
        {value: '1', refsName: 'commodityInfo', type: 'importCommodityInfoModal', title: alias2f085dd487c1465383484787b29fef2c.t('key1005517')},
        {value: '2', refsName: 'importInventory', type: 'importModal', title: alias2f085dd487c1465383484787b29fef2c.t('key1000561')},
        {value: '3', refsName: 'batchPrice', type: 'importModal', title: alias2f085dd487c1465383484787b29fef2c.t('key1005504')},
        {value: '4', refsName: 'batchListing', type: 'importModal', title: alias2f085dd487c1465383484787b29fef2c.t('key1005505')},
      ];
      list.map((item) => {
        if (item.value === value) {
          v.$refs[item.refsName][item.type] = true;
        }
      });
    },
    // 采集1688商品
    collectProductsBtn() {
      const {href} = this.$router.resolve({
        path: '/collectProducts'
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    // 监听重置列表数据
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.tabs_styles {
  /deep/ .ivu-tabs-bar {
    border-bottom: none;
    background-color: #fff;
    padding: 8px 0 3px 0;
    margin: 0;
  }
}
</style>
